<template>
    <div class="row mt-3">
        <div class="col-md-10 col-sm-12 offset-md-1">
            <div class="card border border-dark">
                <div class="card-header bg-dark">
                    Ganancias en los ultimos 6 meses
                </div>
                <div class="card-body">
                    <LineGraphComp id="chart-users" :options="chartOptions" :data="chartDatacomp" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Line as LineGraphComp} from 'vue-chartjs'
import { Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend } from 'chart.js'

ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend)

import irenapi from '../../repositories/services';

export default {
    name: 'GananciasMensualesGraph',
    components: {
        LineGraphComp
    },
    data() {
        return {
            chartData:{
                labels: ['error'],
                datasets: [
                    {
                        label: 'DataError',
                        backgroundColor: '#385F73',
                        data: [4]
                    }
                ],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true
            },
            filterGraphLeases: "mensuales"
        }
    },
    computed: {
        chartDatacomp() {
            return this.chartData
        },
        charttitle() {
            return "datos " + this.filterGraphLeases
        }
    },
    watch: {
        filterGraphLeases(newVal) {
            this.loadChatData(newVal);
        }
    },
    mounted() {
        this.loadChatData("mensuales");
    },
    methods: {
        async loadChatData(value) {
            this.loaded = false;
            try {
                let res = await irenapi.get('/admpannel/leases/graph/profits/getdata');
                this.chartData = { labels: res.data.labels, datasets: [{ data: res.data.datasets.data, label: "usuarios " + value }] };
                this.loaded = true
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>
<style lang='es' scoped>
</style>