<template>
   <div>
     MODULO PARA LA GESTION DE CLIENTES O ARRENDATARIOS
   </div>
</template>
<script>
export default {
   name: 'ClientesView',
   components: {
     
   },
   mixins: [],
   props: {
     
   },
   data() {
     return {
       
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   mounted() {
     
   },
   methods: {
     
   }
};
</script>
<style lang='' scoped>
</style>