<template>
  <v-app>
    <v-app-bar
      app
      color="#111827"
      dark
      v-if="verificarAuth"
    >
      <v-app-bar-nav-icon color="white" @click="drawer = !drawer"> </v-app-bar-nav-icon>
      <v-toolbar-title >
          <div class="d-flex align-center" >
            <v-img
              v-if="!drawer"
              alt="IRENA ADMIN"
              class="shrink mr-2 hidden-sm-and-down"
              contain
              src="./assets/logo2.png"
              transition="scale-transition"
              width="30%"
            />
          </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-row>
        <v-col cols="2" offset="9" >
          <v-menu bottom min-width="200px" rounded offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon x-large v-on="on">
                <v-avatar color="#f1302d" size="48">
                  <span class="white--text text-h5">{{ initials }}</span>
                </v-avatar>
              </v-btn>
            </template>
            <v-card>
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                  <v-avatar color="#f1302d">
                    <span class="white--text text-h5">{{ initials }}</span>
                  </v-avatar>
                  <h4>{{ user.name }}</h4>
                  <p class="text-caption">
                    {{ user.email }}
                  </p>
                  <li v-for="role in user.roleId" :key="role.id" class="text-caption">
                    {{ role.name }}
                  </li>
                  <v-divider class="my-1"></v-divider>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="black--text" v-bind="attrs" v-on="on" icon to="Password">
                        <v-icon>mdi-form-textbox-password</v-icon>
                      </v-btn>
                    </template>
                    <span>Cambiar Contraseña</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="black--text" v-bind="attrs" v-on="on" icon to="Perfil">
                        <v-icon>mdi-account-circle-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Modificar Perfil</span>
                  </v-tooltip>    -->               
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="black--text" v-bind="attrs" v-on="on" icon @click="logout()">
                        <v-icon>mdi-logout</v-icon>
                      </v-btn>
                    </template>
                    <span>Cerrar Sesión</span>
                  </v-tooltip>
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app width="275" v-if="verificarAuth">
      <v-sheet color="#111827">
        <div class="d-flex align-center">
          <a href="/">
            <v-img
              alt="IRENA ADMIN"
              class="shrink mr-2"
              contain
              src="./assets/logo2.png"
              transition="scale-transition"
              height="64"
              width="275"
            />
          </a>
        </div>
      </v-sheet>
      <v-divider></v-divider>
      <v-card text flat height="auto" width="auto">
        <v-list>
          <v-list-group
            v-for="link in this.$store.state.menu"
            :key="link.text"
            v-model="link.active"
            color="#111827"
            :append-icon="(link.opciones.length>1)?'$expand':link.icon"
            :prepend-icon="link.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content v-on:click="menuclicked(link)">
                <v-list-item-title append-icon="mdi-home-roof" v-text="link.text"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="item in link.opciones" v-show="link.opciones.length>1" :key="item.text" router :to="item.route">
              <v-list-item-content >
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>
    </v-navigation-drawer>
    <v-main>
      <v-container class="py-5" fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    drawer: null,
    links: [],
    macroprocesos: [],
    subMenu: [],
    showMantenimiento: false,
    user: []
  }),
  methods:{
    logout() {
      this.$Logout();
    },
    loadMacroprocesos(id) {
      this.macroprocesos = this.$store.state.subMenu.filter((m) => m.modulo.id === id && m.nivel === 1);
      this.subMenu = this.$store.state.subMenu.filter((m) => m.modulo.id === id && m.nivel === 2);
      this.showMantenimiento = this.subMenu.length > 0;
    },
    menuclicked(link){
      
      if(link.opciones.length==1 && this.$route.path!==link.opciones[0].route){
        this.$router.push(link.route);
      }
    }
  },
  computed: {
    verificarAuth() {
      return sessionStorage.getItem("auth");
    },
    verificarRol() {
      return sessionStorage.getItem("rol");
    },
    verificarCatalogos() {
      return this.$store.state.subMenu.length > 0;
    },
    initials(){
      return (this.user.name!==undefined)?this.user.name.substring(0, 1):"";
    }
  },
  created() {
    document.title = "IRENA ADMIN PANEL";

    if (localStorage.token !== undefined && sessionStorage.getItem("auth") === null) {
      sessionStorage.setItem("token", localStorage.token);
      sessionStorage.setItem("auth", true);
      sessionStorage.setItem("rol", "admin");
    }

    if (sessionStorage.getItem("auth") != undefined || sessionStorage.getItem("auth") != null) {
      this.user = this.$store.state.usuario;
    }


    let startDate = localStorage.timeSessionStart;
    let endDate = new Date().getTime();

    //let diff = ((parseInt(endDate)-parseInt(startDate)>=3600000000))?true:false;//una hora
    //let diff = ((parseInt(endDate)-parseInt(startDate)>=60000))?true:false;//un minuto
    let diff = ((parseInt(endDate)-parseInt(startDate)>=600000))?true:false;//diez minutos
    
    if (diff && sessionStorage.getItem("auth") !== undefined) {
      this.logout();
    }
  },
};
</script>


