<template>
    <div class="row mt-3">
        <div class="col-md-10 col-sm-12 offset-md-1">
            <div class="card border border-dark">
                <div class="card-header bg-dark">
                    <template>
                        <v-row>
                            <v-col class="col-md-4 col-sm-12">    
                                <p>Total de arrendamientos {{ filterGraphLeases }}</p>
                            </v-col>
                            <v-col class="col-md-4 col-sm-12 py-2 offset-sm-0 offset-md-4">
                                <v-btn-toggle v-model="filterGraphLeases" tile color="#385F73" group>
                                    <v-btn value="semanales">
                                        Semanal
                                    </v-btn>
                                    <v-btn value="mensuales">
                                        Mensual
                                    </v-btn>
                                    <v-btn value="anuales">
                                        Anual
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>
                        </v-row>
                    </template>
                </div>
                <div class="card-body">
                    <Bar id="chart-leases" :options="chartOptions" :data="chartDatacomp" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
import irenapi from '../../repositories/services';

export default {
    name: 'leasesGraph',
    components: {
        Bar
    },
    data() {
        return {
            chartData: {
                labels: ['January', 'February', 'March'],
                datasets: [
                    { 
                        data: [40, 20, 12], 
                        label: '',
                    }
                ],
            },
            chartOptions: {
                responsive: true
            },
            filterGraphLeases:"mensuales"
        }
    },
    computed: {
        chartDatacomp() {
            return this.chartData
        },
        charttitle() {
            return "datos "+this.filterGraphLeases
        }
    },
    watch: {
        filterGraphLeases(newVal){
            this.loadChatData(newVal);
        }
    },
    mounted() {
        this.loadChatData("mensuales");
    },
    methods: {
        async loadChatData(value){
            this.loaded = false;
            try {
                let res = await irenapi.get('/admpannel/leases/graph/'+value);
                this.chartData = { labels: res.data.labels, datasets: [{ data: res.data.datasets.data, label:"valores "+value }] };
                this.loaded = true
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>
<style lang='es' scoped>
</style>