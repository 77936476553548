import axios from 'axios';
import Vue from 'vue';

const irenapi = axios.create({
        //baseURL:"http://api.irena.docksal.site/api/v1/"
        baseURL:"http://api.irenaapp.com/api/v1/"
    });
    
    //irenapi.defaults.withCredentials = true;

    irenapi.interceptors.response.use(
        (response) => {
          if (response.status === 200) {
            if (response.data.icon !== undefined && response.data.icon === "success") {
              Vue.swal.fire({
                icon: response.data.icon,
                title: response.data.title,
                text: response.data.text,
              });
            }
            if (response.data.icon !== undefined && response.data.icon === "warning") {
              Vue.swal.fire({
                icon: response.data.icon,
                title: response.data.title,
                text: response.data.text,
              });
            }
          }
          if (response.status === 400) {
            if (response.data.icon !== undefined && response.data.icon === "error") {
              Vue.swal.fire({
                icon: response.data.icon,
                title: response.data.title,
                text: response.data.text,
              });
            }
          }
          return response;
        },
        (error) => {
            console.log(error);
          if (error.response.status === 401) {
            Vue.swal
              .fire({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                icon: "error",
                title: "Problema de autorizacion, Se cerrara la sesión inmediatamente",
                //text: error.response.data.message + " " + error.response.status,
                text: "Código de Error: " + " " + error.response.status,
              })
              .then(() => {
                sessionStorage.clear();
                window.location.href = "/";
              });
          }
          if (error.response.status === 403) {
            Vue.swal
              .fire({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                icon: "error",
                title: "No posee los permisos necesarios para dicha acción",
                //text: error.response.data.message + " " + error.response.status,
                text: "Código de Error: " + " " + error.response.status,
              })
              .then(() => {
                //window.location.href = "/";
              });
          }
          if (error.response.status === 422) {
            Vue.swal
              .fire({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                icon: "error",
                title: "El registro no ha sido almacenado, intente de nuevo",
                //text: error.response.data.message + " " + error.response.status,
                text: "Código de Error: " + " " + error.response.status,
              })
              .then(() => {
                window.location.reload();
              });
          }
          if (error.response.status === 500) {
            if (error.response.data.icon !== undefined && error.response.data.icon === "error") {
              Vue.swal.fire({
                icon: error.response.data.icon,
                title: error.response.data.title,
                text: error.response.data.text,
              });
            }
          }
          return error;
        }
      );

      if (sessionStorage.getItem("auth")) {
        irenapi.defaults.headers.common = {
          "Accept": "Application/json",
          "Authorization": "Bearer "+sessionStorage.getItem("token")
      };
    }
    

export default irenapi;