import Vue from 'vue'
import App from './App.vue'
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import store from "./store/store";
import router from "./router/router";
import vuetify from './plugins/vuetify'

import "./plugins/globals";

Vue.use(VueSweetalert2);
Vue.config.productionTip = false


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
