import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard/HomeView";
import ArrendadoresView from "../views/Arrendadores/ArrendadoresView";
import ClientesView from '../views/Clientes/ClientesView'
import ActualesView from '../views/Arrendamientos/ActualesView'
import HistoricosView from '../views/Arrendamientos/HistoricosView'
import SaldosView from '../views/Arrendamientos/SaldosView'
import SoporteView from '../views/Soporte/SoporteView'
import SeguridadView from '../views/Seguridad/SeguridadView'
import LoginView from "../views/LoginView.vue";
import store from "../store/store";

Vue.use(VueRouter);

const routes = [
    {
        path: "*",
        component: LoginView,
    },
    {
        path: "/login",
        name: "Login",
        component: LoginView,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        meta: { auth: true },
        component: Dashboard,
    },
    {
        path: "/arrendadores",
        name: "Arrendadores",
        meta: { auth: true },
        component: ArrendadoresView,
    },
    {
        path: "/clientes",
        name: "Clientes",
        meta: { auth: true },
        component: ClientesView,
    },
    {
        path: "/arrengamientos/actuales",
        name: "Actuales",
        meta: { auth: true },
        component: ActualesView,
    },
    {
        path: "/arrengamientos/historicos",
        name: "Historico",
        meta: { auth: true },
        component: HistoricosView,
    },
    {
        path: "/arrengamientos/saldos",
        name: "Saldos",
        meta: { auth: true },
        component: SaldosView,
    },
    {
        path: "/soporte",
        name: "Soporte",
        meta: { auth: true },
        component: SoporteView,
    },
    {
        path: "/seguridad",
        name: "Securidad",
        meta: { auth: true },
        component: SeguridadView,
    },
];



const router = new VueRouter({
    routes,
    mode: "history"
});

router.beforeEach(function (to, from, next) {

    if (to.matched.some((r) => r.meta.auth) && sessionStorage.getItem("auth") == null) {
        next({ path: "/login" });
    } else {
        next();
    }

    if (sessionStorage.getItem("auth") != null) {
        // validacion para evaluar si tiene permisos a una url
        let opciones = [];
        let verificar = true;
        store.state.menu.forEach((e) => {
            opciones.push(e.opciones);
        });
        opciones.forEach((element) => {
            if (element.route !== to.path) verificar = false;
        });

        if (verificar && to.path !== "/login") {
            next({ path: "/error" });
        }
    }

    if (sessionStorage.getItem("auth") != null) {
        if (to.path == "/") {
            next({ path: "/dashboard" });
        }
    }


});

export default router;
