import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

let vuexStore = null;

const cookieEnabled = navigator.cookieEnabled;

if (cookieEnabled) {
  const vuexLocalStorage = new createPersistedState({
    key: "vuex",
    storage: window.localStorage,
    reducer: (state) => ({
      menu: state.menu,
      parametros: state.parametros,
      permissions: state.permissions,
      usuario: state.usuario,
    }),
  });

  vuexStore = new Vuex.Store({
    state() {
      return {
        menu: [],
        parametros: [],
        permissions: [],
        usuario: []
      };
    },
    mutations: {
      setMenu(state, payload) {
        state.menu = payload.menu;
      },
      setParametro(state,payload) {
        state.parametros = payload.parametros;
      },
      setPermissions(state, payload) {
        state.permissions = payload.permissions;
      },
      setUsuario(state, payload) {
        state.usuario = payload.usuario;
      }
    },
    actions: {
      doMenu(context, menu) {
        context.commit("setMenu", { menu });
      },
      doSubMenu(context, subMenu) {
        context.commit("setSubMenu", { subMenu });
      },
      doPermissions(context, permissions) {
        context.commit("setPermissions", { permissions });
      },
      doUsuario(context, usuario) {
        context.commit("setUsuario", { usuario });
      },
    },
    modules: {},
    getters: {},
    plugins: [vuexLocalStorage],
  });
}

export default vuexStore;
