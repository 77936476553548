import Vue from "vue";
import axios from 'axios';

const logout = function () {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("auth");
    sessionStorage.clear();
    localStorage.clear();
    window.location = "/login";
  };

  // Función global para descargar documentos
const descargarDocumento = function (url, nombreArchivo) {
  try {
    axios({
      url: url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombreArchivo);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  } catch (error) {
    console.log(error)
  }
};

Vue.prototype.$Logout = logout;
Vue.prototype.$DescargarDocumento = descargarDocumento;