<template>
    <v-row align="center" justify="center">
        <v-card outlined height="auto" class="my-15 mt-60 mx-a" v-if="showLogin">
            <v-toolbar color="#F7F7F7">
                <v-card-title class="text-h6 font-weight-bold text-darken-4">
                    IRENA APP ADMIN PANEL
                </v-card-title>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field :rules="[rules.required, rules.email]" v-model="user.email" label="Usuario"
                        prepend-icon="mdi-account-circle"></v-text-field>
                    <v-text-field :rules="[rules.required, rules.min]" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'" v-model="user.password" @click:append="show1 = !show1"
                        v-on:keyup.enter="login" label="Contraseña" prepend-icon="mdi-lock"></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-flex class="text-center">
                    <v-btn :disabled="!valid" color="info" @click="login" centered> Ingresar </v-btn>
                </v-flex>
            </v-card-actions>
            <v-progress-linear v-if="loading" height="10" indeterminate rounded striped color="teal" />
        </v-card>
        <v-snackbar v-model="snackbar" shaped centered color="#479D95" class="text-center pl-1" width="150px" height="150px"
            :timeout="timeout">
            Datos no válidos, intente de nuevo
        </v-snackbar>
    </v-row>
</template>
<script>
import irenapi from "../repositories/services";
export default {
    name: "LoginView",
    data() {
        return {
            loading: false,
            snackbar: false,
            timeout: -1,
            show1: false,
            valid: true,
            alert: false,
            showLogin: sessionStorage.getItem("token") !== null ? false : true,
            user: {
                email: "",
                password: "",
            },
            token: null,
            rules: {
                required: (value) => !!value || "El campo es requerido.",
                min: (value) => (value || "").length >= 8 || "8 caracteres como mínimo",
                email: (value) => {
                    if (value !== "") {
                        const pattern =
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || "El email ingresado es inválido";
                    } else {
                        return;
                    }
                },
            },
        };
    },
    methods: {
        async login() {
            this.loading = true;
            if (this.$refs.form.validate()) {

                try {
     
                    let response = await irenapi.post('/users/login',this.user);
                    if (response.status !== 201) {
                            this.loading = false;
                            this.$refs.form.reset();
                            this.snackbar = true;
                            this.timeout = 2000;
                        } else {
                            //Set Token Var
                            sessionStorage.setItem("token", response.data.token);
                            localStorage.token = response.data.token;
                            localStorage.timeSessionStart = new Date().getTime();
                            sessionStorage.setItem("auth", true);
                            sessionStorage.setItem("rol", "admin");
                            this.$store.dispatch("doMenu", response.data.menu);
                            this.$store.dispatch("doPermissions", response.data.permisos);
                            this.$store.dispatch("doUsuario", response.data.user);
                            this.$store.dispatch("doFeriadoId", response.data.feriadoId);
                            this.$store.dispatch("doParametro", response.data.parametros);
                            window.location = "/";
                        }
                    
                } catch (error) {
                    console.log("Error" + error.response.status);
                }

            } else {
                this.loading = false;
            }
        },
    }
};
</script>
  