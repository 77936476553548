<template>
  <div>
    <v-row>
      <LeasesGraph></LeasesGraph>
    </v-row>
    <v-row class="mt-12">
      <v-container class="col-md-10 col-sm-12 offset-md-1">
      <v-row dense>
        <v-col class="col-md-3 col-sm-12">
          <v-card color="#385F73" dark v-on:click="()=>{this.$router.push('/clientes')}">
            <v-card-title class="text-h5">
              125
            </v-card-title>
            <v-card-subtitle>
              Total de clientes
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col class="col-md-3 col-sm-12">
          <v-card color="#385F73" dark v-on:click="()=>{this.$router.push('/arrendadores')}">
            <v-card-title class="text-h5">
              20
            </v-card-title>
            <v-card-subtitle>
              Arrendadores
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col class="col-md-3 col-sm-12">
          <v-card color="#385F73" dark>
            <v-card-title class="text-h5">
              $1234.56
            </v-card-title>
            <v-card-subtitle>
              Ganancias del mes
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col class="col-md-3 col-sm-12">
          <v-card color="#385F73" dark v-on:click="()=>{this.$router.push('/arrengamientos/actuales')}">
            <v-card-title class="text-h5">
              10
            </v-card-title>
            <v-card-subtitle>
              Arrendamientos Activos
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="col-md-3 col-sm-12">
          <v-card color="#385F73" dark v-on:click="()=>{this.$router.push('/clientes')}">
            <v-card-title class="text-h5">
              20
            </v-card-title>
            <v-card-subtitle>
              Nuevos Usuarios del mes
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col class="col-md-3 col-sm-12">
          <v-card color="#385F73" dark v-on:click="()=>{this.$router.push('/soporte')}">
            <v-card-title class="text-h5">
              35
            </v-card-title>
            <v-card-subtitle>
              Chats Pendientes
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col class="col-md-3 col-sm-12">
          <v-card color="#385F73" dark v-on:click="()=>{this.$router.push('/arrengamientos/saldos')}">
            <v-card-title class="text-h5">
              50
            </v-card-title>
            <v-card-subtitle>
              Saldos pendientes
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col class="col-md-3 col-sm-12">
          <v-card color="#385F73" dark>
            <v-card-title class="text-h5">
              50
            </v-card-title>
            <v-card-subtitle>
              Emails Enviados
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    </v-row>
    <v-row class="mt-8 mb-12">
      <v-col class="col-md-5 col-sm-12 offset-md-1">
        <v-card>
          <GananciasMensualesGraph></GananciasMensualesGraph>
        </v-card>
      </v-col>
      <v-col class="col-md-5 col-sm-12 bg">
        <v-card>  
          <NewUsersGraph></NewUsersGraph>
        </v-card>
      </v-col>
    </v-row> 
  </div>
</template>

<script>
import LeasesGraph from '../../components/Dashboard/LeasesGraph.vue';
import GananciasMensualesGraph from '../../components/Dashboard/GananciasMensualesGraph.vue'
import NewUsersGraph from '../../components/Dashboard/NewUsersGraph.vue';
export default {
  name: "HomeView",
  components: { LeasesGraph, GananciasMensualesGraph,NewUsersGraph }
};

</script>
