<template>
  <div>
    <v-row>
      <v-col xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card>
          <v-toolbar>
            <v-card-title class="text-h5 font-weight-bold text-darken-4">
              Gestion de Arrendadores
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <!-- Data Table -->
          <v-card-text>
            <v-data-table color="#009ddd" :headers="headerTable" :items="items" :items-per-page="itemsPerPage"
              :loading="loading" loading-text="Cargando... espere por favor" class="elevation-22" hide-default-footer>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title></v-toolbar-title>
                  <v-col class="d-flex" cols="3" sm="3">
                    <v-select :items="parametros" v-model="parametro" label="Parametro de busqueda"
                      hint="Seleccione el parámetro de búsqueda" single-line></v-select>
                  </v-col>
                  <v-col v-if="parametro == 'Estado'" class="d-flex" cols="3" sm="3">
                    <v-select :items="selectStateParams" v-model="selectedStateParam" label="Estado"
                      hint="Seleccione la opcion deseada" single-line></v-select>
                  </v-col>
                  <v-col v-else class="d-flex" cols="3" sm="3">
                    <v-text-field v-model="search" label="Valor" hint="Digite el valor a buscar"
                      single-line></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="6" sm="6">
                    <v-col cols="4" sm="4">
                      <v-btn color="#17a7a8" right class="font-weight-bold white--text" rounded @click="buscar">
                        <v-icon>mdi-layers-search-outline </v-icon>
                        Buscar
                      </v-btn>
                    </v-col>
                    <v-col cols="4" sm="4">
                      <v-btn color="#17a7a8" right class="font-weight-bold white--text" rounded @click="limpiar">
                        <v-icon>mdi-layers-off-outline </v-icon>
                        Limpiar
                      </v-btn>
                    </v-col>
                    <v-col cols="4" sm="4">
                      <!-- Dialog Nuevo|Editar -->
                      <v-dialog v-model="dialog" persistent max-width="800px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :disabled="true" color="#111827" right class="font-weight-bold white--text" rounded v-bind="attrs"
                            v-on="on">
                            <v-icon> mdi-plus-circle-outline </v-icon>
                            Nuevo
                          </v-btn>
                        </template>
                        <v-card>
                          <v-toolbar color="#111827">
                            <v-card-title color="#111827" class="text-h5 font-weight-bold indigo--text text-darken-4">
                              <span class="text-h5 indigo--text text-darken-4">{{ formTitle }}</span>
                              <v-spacer></v-spacer>
                            </v-card-title>
                          </v-toolbar>
                          <v-form ref="form" v-model="valid" lazy-validation>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="9" md="9">
                                    <v-text-field :rules="[rules.required]" v-model="editedItem.nombre"
                                      label="Nombre"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="3" md="3">
                                    <v-text-field :rules="[rules.required, rules.min, rules.max]" type="number" min="0"
                                      max="100" v-model="editedItem.porcentaje" label="Porcentaje(%)"></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="#006600" right class="font-weight-bold white--text" @click="save"
                              :disabled="!valid">
                              Guardar
                            </v-btn>
                            <v-btn color="#FF441B" right @click="close" outlined> Cancelar </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- Fin Dialog Nuevo|Editar -->

                      <!-- Inicio Dialog Eliminar -->
                      <v-dialog v-model="dialogDelete" persistent max-width="600px">
                        <v-card>
                          <v-toolbar color="#F7F7F7">
                            <v-card-title color="#F7F7F7"
                              class="text-h5 font-weight-bold text-darken-4">¿Está seguro que quiere eliminar
                              este registro?</v-card-title>
                          </v-toolbar>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="#006600" right class="font-weight-bold white--text" @click="deleteItemConfirm"
                              :disabled="!valid">OK</v-btn>
                            <v-btn color="#FF441B" right @click="closeDelete" outlined> Cancel</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- Fin Dialog Eliminar-->
                    </v-col>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <!-- Acciones -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="deep-orange lighten-1" dark @click="verLessorModal(item)" v-bind="attrs" v-on="on">
                      mdi-information-variant
                    </v-icon>
                  </template>
                  <span>Información</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small class="mr-2" color="deep-orange lighten-1" v-on="on"
                      @click="verDocumentosPendientes(item)">
                      mdi-list-status
                    </v-icon>
                  </template>
                  <span>Ver Documentos</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status == 1">
                  <template v-slot:activator="{ on }">
                    <v-icon small class="mr-2" color="deep-orange lighten-1" v-on="on" @click="manageLessorEnabled(item)">
                      {{item.enabled?'mdi-close':'mdi-check'}}
                    </v-icon>
                  </template>
                  <span>{{item.enabled?'Desactivar Arrendador':'Activar Arrendador'}}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status == 1">
                  <template v-slot:activator="{ on }">
                    <v-icon small :disabled="true" class="mr-2" color="deep-orange lighten-1" v-on="on" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar Registro</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status == 1">
                  <template v-slot:activator="{ on }">
                    <v-icon small class="mr-2" color="deep-orange lighten-1" v-on="on" @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Deshabilitar Registro</span>
                </v-tooltip>
              </template>
              <!-- Fin Acciones -->
            </v-data-table>
            <!-- modal vehiculos -->
            <v-dialog v-model="dialogVehiclesPhotos" persistent max-width="auto">
              <v-card class="px-2">
                <div>
                  <v-carousel hide-delimiters>
                    <v-carousel-item
                      v-for="(item,i) in items"
                      :key="i"
                      :src="item.src"
                    ></v-carousel-item>
                  </v-carousel>
                </div>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialogVehiclesPhotos = false">Cerrar</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogVehicles" persistent max-width="auto">
              <v-card class="px-2">
                <v-card-title class="text-h5">Listado de vehiculos ingresados</v-card-title>
                <v-data-table :headers="headerVehicles" :items="vehicles" class="elevation-22">
                  <template v-slot:[`item.daily_amount`]="{ item }">
                    <span> ${{ formatPrice(item.daily_amount) }}</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="downloadDocument(item)" :disabled="item.path == null" 
                          color="deep-orange"
                          dark v-bind="attrs" v-on="on">
                          mdi-download
                        </v-icon>
                      </template>
                      <span>Descargar Documento</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="()=>{}" 
                          color="deep-orange"
                          dark v-bind="attrs" v-on="on">
                          mdi-image-multiple
                        </v-icon>
                      </template>
                      <span>Ver Imagenes</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon small class="mr-2" color="deep-orange lighten-1" v-on="on" @click="manageVehicleEnabled(item)">
                          {{item.enabled?'mdi-close':'mdi-check'}}
                        </v-icon>
                      </template>
                      <span>{{item.enabled?'Desactivar vehiculo':'Activar vehiculo'}}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialogVehicles = false">Cerrar</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- modal documentos -->
            <v-dialog v-model="dialogItemDocumentos" persistent max-width="auto">
              <v-card class="px-2">
                <v-card-title class="text-h5">Listado de Documentos</v-card-title>
                <v-data-table :headers="headersItemDocumentos" :items="documentos" class="elevation-22">
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="downloadDocument(item)" v-if="item.path != null" color="deep-orange lighten-1"
                          dark v-bind="attrs" v-on="on">
                          mdi-download
                        </v-icon>
                      </template>
                      <span>Descargar Documento</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="(item.state == 'Evaluacion')">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="deep-orange lighten-1" dark v-bind="attrs" v-on="on"
                          @click="manageStates(item, 'aproved')">
                          mdi-check
                        </v-icon>
                      </template>
                      <span>Aprobar documento</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="(item.state == 'Evaluacion')">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="deep-orange lighten-1" dark v-bind="attrs" v-on="on"
                          @click="manageStates(item, 'denied')">
                          mdi-cancel
                        </v-icon>
                      </template>
                      <span>Rechazar documento</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialogItemDocumentos = false">Cerrar</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- fin modal documentos -->
            <!-- modal informacion -->
            <v-dialog v-model="lessorInfoModal" persistent max-width="80%">
              <v-card class="px-2 mx-auto" >
                <v-card-title class="text-h5">Informacion de Arrendador
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon small class="mr-2" color="deep-orange lighten-1" v-on="on" @click="verVehiculos()">
                        mdi-car
                      </v-icon>
                    </template>
                    <span>Gestion de vehiculos</span>
                  </v-tooltip>
                  <v-btn density="compact" @click="manageLessorAccess()">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon small class="mr-2" color="deep-orange lighten-1" v-on="on">
                          {{selectedlessor.user_enabled?'mdi-lock':'mdi-lock-open'}}
                        </v-icon>
                      </template>
                      <span>{{selectedlessor.user_enabled?'Restringir accesso':'Habilitar accesso'}}</span>
                    </v-tooltip>
                  </v-btn>
                </v-card-title>
                <div>
                 <v-container width="10%">
                  <v-row justify="center" align="center">
                    <v-col cols="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Nombre</v-list-item-title>
                          <v-list-item-subtitle>{{selectedlessor.name || "--" }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Email</v-list-item-title>
                          <v-list-item-subtitle>{{selectedlessor.email || "--" }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row justify="start" align="center">
                    <v-col cols="4">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Pais</v-list-item-title>
                          <v-list-item-subtitle>{{selectedlessor.country || "--" }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="4">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Departamento/Estado</v-list-item-title>
                          <v-list-item-subtitle>{{selectedlessor.state || "--" }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="4">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Municipio/Ciudad</v-list-item-title>
                          <v-list-item-subtitle>{{selectedlessor.city || "--" }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row justify="start" align="center">
                    <v-col cols="4">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Direccion</v-list-item-title>
                          <v-list-item-subtitle>{{selectedlessor.address || "--" }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="4">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Fecha de Cumpleaños</v-list-item-title>
                          <v-list-item-subtitle>{{selectedlessor.birthday || "--" }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="4">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Telefono</v-list-item-title>
                          <v-list-item-subtitle>{{selectedlessor.phone || "--" }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row >
                    <v-col cols="4">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Banco</v-list-item-title>
                          <v-list-item-subtitle>{{selectedlessor.bank_account || "--" }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="4">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Tipo de cuenta</v-list-item-title>
                          <v-list-item-subtitle>{{selectedlessor.type_account || "--" }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="4">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Numero de cuenta</v-list-item-title>
                          <v-list-item-subtitle>{{selectedlessor.number_account || "--" }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                 </v-container>
                </div>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeLessorInfoModal()">Cerrar</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- fin modal informacion -->
            <div class="text-center pt-2">
              <v-pagination :length="pageCount" :total-visible="10" v-model="page" @input="loaditems(page)">
              </v-pagination>
            </div>
          </v-card-text>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import irenapi from '../../repositories/services';
export default {
  name: 'ArrendadoresView',
  data() {
    return {
      items: [],
      documentos: [],
      vehicles: [],
      selectedlessor: {},
      selectedVehicle: {},
      valid: true,
      dialog: false,
      dialogVehiclesPhotos: false,
      dialogItemDocumentos: false,
      dialogVehicles: false,
      lessorInfoModal: false,
      dialogDelete: false,
      idDelete: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      parametros: ["Nombre", "Email", "Estado"],
      selectStateParams: ["Aprobado", "Pendiente", "Denegado", "Incompleto"],
      selectedStateParam: "",
      parametro: "",
      loading: true,
      // Header Leassers
      headersItemDocumentos: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
          class: "teal lighten-2 white--text",
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "state",
          class: "teal lighten-2 white--text",
        },
        {
          text: "Ultima Actualizacion",
          align: "start",
          sortable: true,
          value: "last_update",
          class: "teal lighten-2 white--text",
        },
        {
          text: "Opciones",
          align: "start",
          sortable: false,
          value: "actions",
          class: "teal lighten-2 white--text",
        },
      ],
      headerVehicles: [
        {
          text: "Marca",
          align: "start",
          sortable: true,
          value: "brand",
          class: "teal lighten-2 white--text",
        },
        {
          text: "Modelo",
          align: "start",
          sortable: true,
          value: "model",
          class: "teal lighten-2 white--text",
        },
        {
          text: "Año",
          align: "start",
          sortable: true,
          value: "year",
          class: "teal lighten-2 white--text",
        },
        {
          text: "V/D($)",
          align: "start",
          sortable: true,
          value: "daily_amount",
          class: "teal lighten-2 white--text",
        },
        {
          text: "Placas",
          align: "start",
          sortable: true,
          value: "plates",
          class: "teal lighten-2 white--text",
        },
        {
          text: "Opciones",
          align: "start",
          sortable: false,
          value: "actions",
          class: "teal lighten-2 white--text",
        },
      ],
      headerTable: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
          class: "teal lighten-2 white--text",
        },
        {
          text: "Correo",
          align: "start",
          sortable: true,
          value: "email",
          class: "teal lighten-2 white--text",
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "display_status",
          class: "teal lighten-2 white--text",
        },
        {
          text: "Opciones",
          align: "start",
          sortable: false,
          value: "actions",
          class: "teal lighten-2 white--text",
        },
      ],
      editedIndex: -1,
      editedItem: {
        id: 0,
        monto: 0,
        fecha_estimada: "",
        fecha_ejecucion: "",
        comentario: "",
        correlativo: "",
        factura: "",
        impuestos: []
      },
      defaultItem: {
        id: 0,
        monto: 0,
        fecha_estimada: "",
        fecha_ejecucion: "",
        comentario: "",
        correlativo: "",
        factura: "",
        impuestos: []
      },
      // Arreglo para formato de moneda
      options1: {
        locale: "en-US",
        prefix: "US$",
        suffix: "",
        length: 15,
        precision: 4,
      },
      rules: {
        required: (value) => !!value || "El campo es requerido.",
      },
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo quedan" : "Editar quedan";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
      //return false;
    },
    dialogPagos(val) {
      val || this.closePagos();
      //return false;
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(".", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.selectedPago = item.selected;
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.idDelete = item.id;
    },
    deleteItemConfirm() {
      irenapi
        .delete("quedan/delete/" + this.idDelete, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then(() => {
          this.loaditems();
        });
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
        this.editedIndex = -1;
      });
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.idDelete = "";
      });
    },
    closeDocuments() {
      this.dialogItemDocumentos = false;
    },
    closeLessorInfoModal() {
      this.lessorInfoModal = false;
    },
    closeVehicles() {
      this.dialogVehicles = false;
    },
    manageVehicleEnabled(item){
      this.$swal({
            title: (item.enabled)?"¿Está seguro de desahabilitar el vehiculo?"
                    :"¿Está seguro de habilitar el vehiculo?",
            text: (item.enabled)?"Una vez desabilite el vehiculo, este no sera visible para los clientes de la APP."
                    :"Una vez habilite el vehiculo, este sera visible en la Applicacion.",
            showCancelButton: true,
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !this.$swal.isLoading(),
        }).then(async (result) => {
            if (result.isConfirmed) {

             let response =  await irenapi.post('admpannel/vehicles/manage-enabled/'+item.id,{
                status:!item.enabled
              });

              if(response.status==200){
                this.loaditems();
                this.$swal({
                  'title':'Exito',
                  'icon':'success',
                  'text':'Vehiculo modificado con exito.'
                });
              }else{
                this.$swal({
                  'title':'Problema',
                  'icon':'error',
                  'text':'ocurrio un problema al realizar la accion.'
                });
              }


              this.closeVehicles();
              this.closeLessorInfoModal();
            }

            return;
        });
    },
    manageLessorEnabled(item){
      this.$swal({
            title: (item.enabled)?"¿Está seguro de desaabilitar el arrendador?"
                    :"¿Está seguro de habilitar el arrendador?",
            text: (item.enabled)?"Una vez desabilite el usuario, sus vehiculos no estaran disponibles para ser arrendados."
                    :"Una vez habilite el Arrendador, este podra hacer uso completo del sistema.",
            showCancelButton: true,
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !this.$swal.isLoading(),
        }).then(async (result) => {
            if (result.isConfirmed) {
              let response = await irenapi.post('admpannel/lessors/manage-enabled/'+item.id,{
                status:!item.enabled
              }); 
              
              console.log(response);
              this.loaditems();
            }

            return;
        });
    },
    manageLessorAccess(){
      this.$swal({
            title: "¿Está seguro de desabilitar el accesso del arrendador?",              
            text: "Una vez desabilite el usuario, este no podra acceder al sistema.",
            showCancelButton: true,
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !this.$swal.isLoading(),
        }).then(async (result) => {
            if (result.isConfirmed) {
              await irenapi.post('admpannel/lessors/manage-access/'+this.selectedlessor.id,{
                status:!this.selectedlessor.user_enabled
              });
              this.loaditems();
            }

            return;
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.editedItem.impuestosSeleccionados = this.editedItem.impuestos.filter((item) => item.active).map(item => item.id);
          irenapi
            .put("quedan/update/" + this.editedItem.id, this.editedItem, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            })
            .then(() => {
              this.loaditems();
              this.$refs.form.resetValidation();
              this.$refs.form.reset();
            })
            .catch(function (error) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            });
        } else {
          this.editedItem.proceso_pago_id = this.selectedPago.id;
          this.editedItem.impuestosSeleccionados = this.editedItem.impuestos.filter((item) => item.active).map(item => item.id);
          irenapi
            .post("quedan/create/", this.editedItem, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            })
            .then(() => {
              this.loaditems();
              this.$refs.form.resetValidation();
              this.$refs.form.reset();
            }).catch(function (error) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            });
        }
        this.close();
      }
    },
    buscar() {
      var searchable = "";
      if (this.parametro === "Estado") {
        if (this.selectedStateParam == "Aprobado") {
          searchable = "Aprobado";
        } else if (this.selectedStateParam == "Pendiente") {
          searchable = "Pendiente de Revision";

        } else if (this.selectedStateParam == "Denegado") {
          searchable = "Denegado";

        } else {
          searchable = "Cuenta Creada";

        }
      } else {
        searchable = this.search;
      }
      console.log(searchable);

      this.loaditems(1, "&parametro=" + this.parametro, "&search=" + searchable);
    },
    limpiar() {
      this.loaditems(1, "&parametro=", "&search=");
      this.parametro = "";
      this.search = "";
    },
    verDocumentosPendientes(item) {
      this.documentos = item.documents;
      this.dialogItemDocumentos = true;
    },
    verLessorModal(item) {
      this.selectedlessor = {...item};
      this.lessorInfoModal = true;
    },
    verVehiculos() {
      this.closeDocuments();
      this.vehicles = this.selectedlessor?.vehicles;
      this.dialogVehicles = true;
    },
    loaditems(page, parametro, search) {
      this.loading = true;
      if (typeof page === "undefined") {
        this.page = 1;
      }
      if (this.page != 1) {
        this.items = [];
      }
      if (this.parametro !== undefined && parametro !== "" && this.search !== undefined && search !== "") {
        parametro = "&parametro=" + this.parametro;
        search = "&search=" + this.search;
      } else {
        parametro = "";
        search = "";
        this.page = 1;
      }
      irenapi
        .get("/admpannel/lessors/index?page=" + this.page + parametro + search, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.items = response.data.data;
          this.pageCount = response.data.meta.last_page;
          this.loading = false;
        });
    },
    async manageStates(item, param) {

      await irenapi.post('/admpannel/lessors/manage-state/' + item.id, {
        state: param
      }).catch(function (error) {
        console.log(error)
      });

      this.loaditems();
      this.closeDocuments();

    },
    downloadDocument(item) {
      let parts = item.path.split(/[/\\\\]/);
      let nombreArchivo = parts.pop();
      this.$DescargarDocumento("http://api.irena.docksal.site/api/v1/admpannel/lessors/download-document/" + item.id, nombreArchivo);
    },
  },
  created() {
    this.loaditems();
  }
};
</script>
<style lang='' scoped>
</style>